/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";

import { Header, Sidebar, ContentItem, Footer, ProfilVideo } from "../../parts";
import OwlCarousel from 'react-owl-carousel';
import { resetStyle, attachLandingStyle, attachSignedInStyle } from "../../libs/helper";

const Landing = (props) => {
	const { callAction, call_action } = props;
	const [recent, setRecent] = useState([]);
	const [profil, setProfil] = useState(null);
	const [recommended, setRecommended] = useState([]);
	const [myvideos, setMyVideos] = useState([]);
	const [popular, setPopular] = useState([]);
	const [update, setUpdate] = useState(0);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(null);
	const [daerah_id, setDaerah] = useState(0);
	const sm = window.innerWidth < 768;
	const doSearch = () => {
		if(search.length > 3) document.location = "/search?q=" + search;
	}
	const loadCategories = () => {
		callAction(call_action, 'categories', {
			endpoint: '/tuxe/categories'
		});
	}
	const handleContent = (payload) => {
		if (typeof payload === 'undefined') return;
		if (payload === null) return;
		setProfil(payload.profil ? payload.profil : null);
		setRecent(payload.recent ? payload.recent : []);
		setPopular(payload.popular ? payload.popular : []);

		setRecommended(payload.recommended ? payload.recommended : []);
		setMyVideos(payload.myvideo ? payload.myvideo : []);
		setUpdate(update + 1);
		console.log("done");
		loadCategories();
	}

	useEffect(() => {
		let login_state = localStorage.getItem("is_login");
		resetStyle();
		if (login_state === '1') {
			attachSignedInStyle();
		} else {
			attachLandingStyle();
		}


		let mdid = parseInt(localStorage.getItem("mdid"));

		callAction(call_action, 'home', {
			endpoint: '/tuxe/home'
		})
		setLoading(true);
		setDaerah(mdid);
	}, []);


	useEffect(() => {
		if (loading && typeof call_action.home !== 'undefined' && call_action.home !== null) {
			console.log("loading done", call_action.home);

			handleContent(call_action.home);
			setLoading(false);
		}

	}, [
		call_action.home]);


	useEffect(() => {
		if(call_action.categories){
			let payload = call_action.categories;
			if(payload.categories) localStorage.setItem("categories",JSON.stringify(payload.categories));
			if(payload.klaster) localStorage.setItem("klaster",JSON.stringify(payload.klaster));
			
		}
	}, [
		call_action.categories]);



	return (
		<>
			<Header place="landing" />
			<section id="slider" class="slider-element home-top-banner slider-parallax dark linear-bg-overlay" style={{ "background": "url('images/home-top-banner-bg.jpg')", "backgroundSize": "cover", "paddingTop": "50px" }} data-height-xl="670" data-height-lg="80vh" data-height-md="60vh" data-height-sm="60vh" data-height-xs="60vh">
				<div class="slider-parallax-inner">
					<div class="container clearfix">
						<div class="vertical-middle center">
							<div class="home-top-content center full">
								<img alt="logo-kemendagri" width={sm ? "15%" : "8%"} src="/logo-kemendagri.png" />
								<h3 style={{ fontWeight: "bold", fontSize: sm ? 16 : 24 }}>KEMENTERIAN DALAM NEGERI<br />
									BADAN STRATEGI KEBIJAKAN DALAM NEGERI</h3>
								<h1 style={{ fontSize: sm ? 24 : 50 }}>Silahkan kunjungi galeri video<br />
									inovasi yang anda minati</h1>
								<form class="searchbox-home">
									<div class="form-group">
										<label>Ketikan nama inovasi atau judul video atau nama pemerintah daerah</label>
										<div class="input-group mb-3">
											<input type="text" class="form-control" placeholder="Cari video inovasi" onBlur={(evt) => {
												setSearch(evt.target.value);
											}} />
											<div class="input-group-append">
												<button class="button" type="button" onClick={() => {
													doSearch();
												}}>Search</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="content">
				<div class="content-wrap">
					<div class="section nomargin dark">
						<div class="container">
							<div class="articles-wrapper">
								<div class="heading-blocks heading-block-inline">
									<h3>Paling Populer</h3>
									<a href={'/popular'} class="simple-link">Lihat semua video</a>
								</div>
								{popular.length > 0 && <OwlCarousel dots={false} id="oc-images" className={'owl-theme owl-carousel image-carousel carousel-widget'} loop margin={10} nav>
									{popular.map((item, idx) => {

										return <div key={idx} class="oc-item video-article-item item">
											<a href={`/detail_inovasi/${item.id}`}>
												<img src={item.has_video === 1 ? item.indikator_video.thumbnail_url : 'https://api.tuxedovation.com/file/novideo.jpg'} class="thumbnail-img" />
												<h4 class="meta-title">{item.nama}</h4>
											</a>
											<a href="#" class="meta-location">{item.pemda}</a>
											<div class="meta-views"><span class="iconify" data-icon="bi:eye"></span>{item.created_at}</div> 
											{/* {item.views} views - */}
										</div>
									})}
								</OwlCarousel>}
							</div>
							<hr class="line-divider" />
							<div class="articles-wrapper">
								<div class="heading-blocks heading-block-inline">
									<h3>Rekomendasi</h3>
									<a href={'/new_release'} class="simple-link">Lihat semua video</a>
								</div>
								{recommended.length > 0 && <OwlCarousel dots={false} id="oc-images" className={'owl-theme owl-carousel image-carousel carousel-widget'} loop margin={10} nav>
									{recommended.map((item, idx) => {

										return <div key={idx} class="oc-item video-article-item item">
											<a href={`/detail_inovasi/${item.id}`}>
												<img src={item.has_video === 1 ? item.indikator_video.thumbnail_url : 'https://api.tuxedovation.com/file/novideo.jpg'} class="thumbnail-img" />
												<h4 class="meta-title">{item.nama}</h4>
											</a>
											<a href="#" class="meta-location">{item.pemda}</a>
											<div class="meta-views"><span class="iconify" data-icon="bi:eye"></span>  {item.created_at}</div>
											{/* {item.views} views - */}
										</div>
									})}
								</OwlCarousel>}
							</div>
							<hr class="line-divider" />
							<div class="articles-wrapper">
								<div class="heading-blocks heading-block-inline">
									<h3>Terbaru</h3>
									<a href={'/new_release'} class="simple-link">Lihat semua video</a>
								</div>

								{recent.length > 0 && <OwlCarousel dots={false} id="oc-images" className={'owl-theme owl-carousel image-carousel carousel-widget'} loop margin={10} nav>
									{recent.map((item, idx) => {

										return <div key={idx} class="oc-item video-article-item item">
											<a href={`/detail_inovasi/${item.id}`}>
												<img src={item.has_video === 1 ? item.indikator_video.thumbnail_url : 'https://api.tuxedovation.com/file/novideo.jpg'} class="thumbnail-img" />
												<h4 class="meta-title">{item.nama}</h4>
											</a>
											<a href="#" class="meta-location">{item.pemda}</a>
											<div class="meta-views"><span class="iconify" data-icon="bi:eye"></span> {item.created_at}</div>
											{/* {item.views} views -  */}
										</div>
									})}
								</OwlCarousel>}

							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Landing;